<template>
  <div class="signCreaditBook-wrap">
    <div class="content">
      <div class="companyName">
        <span>{{ comName }}</span
        >的认证授权书
      </div>
      <div class="userName">需法定代表人 {{ name }} 完成实名认证并签署</div>
      <div class="img-wrap">
        <img :src="getImgUrl(legalCreditUrl)" alt @click="HandleclickImg" />
      </div>
      <div class="btn" @click="submit">提交</div>
    </div>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="code-wrap">
          <div class="content">
            <div class="title">验证码签署</div>
            <div class="input">{{ telephone || email }}</div>
            <div class="code">
              <van-field
                v-model="verifyCode"
                placeholder="请输入验证码"
                oninput="value= value.replace(/[^\d]/g, '')"
                maxlength="6"
              >
                <template #button>
                  <div class="label" v-if="!isSend" @click="sendCode">
                    获取验证码
                  </div>
                  <div class="label" v-else>重新发送（{{ num }}）</div>
                </template>
              </van-field>
            </div>
          </div>
          <div class="btn-wrap">
            <div class="left" @click="show = false">取消</div>
            <van-button
              class="right"
              :loading="confirmLoading"
              @click="onConfirm"
              >确认</van-button
            >
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
  import ApiCreadit from '@/api/creadit'
  import makeSeal from '@/utils/makeSeal'
  import Api from '@/api'
  import sealApi from '@/api/seal'
  import { Toast, ImagePreview } from 'vant'
  import { drawEllipseSeal } from '@/lib/drawEllipseSeal'
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        legalCreditUrl: '',
        comName: '',
        name: '',
        num: 60,
        show: false,
        telephone: '',
        email: '',
        verifyCode: '',
        isSend: false,
        tokenInfo: {},
        confirmLoading: false,
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
      isZdd() {
        return this.$store.getters['system/isZdd']
      },
    },
    created() {
      this.tokenInfo = this.$jsonPase(
        window.sessionStorage.getItem('tokenInfo')
      )
      this.telephone =
        this.tokenInfo.telephone && this.tokenInfo.telephone.length
          ? this.tokenInfo.telephone
          : null
      this.email =
        this.tokenInfo.email && this.tokenInfo.email.length
          ? this.tokenInfo.email
          : null
      this.name = this.tokenInfo.userFullName
      this.getSignFile()
      this.validLegal()
    },
    destroyed() {
      if (this.timer) clearInterval(this.timer)
    },
    methods: {
      HandleclickImg() {
        ImagePreview({
          images: [this.legalCreditUrl],
          showIndex: false,
          onClose() {
            // do something
          },
        })
      },
      validLegal() {
        ApiCreadit.validLegal({
          comId: this.tokenInfo.comId,
          userId: this.tokenInfo.userId,
        }).then(res => {
          const { code } = res
          // 200:一致去签署 201:身份不一致 204:未注册 205:未实名
          if (code === 201) {
            Toast('身份不一致')
            this.$router.push('/list')
          }
        })
      },
      submit() {
        this.show = true
      },
      async onConfirm() {
        try {
          this.confirmLoading = true
          const sealBase64 = makeSeal({
            radioFlag: 1, // 是否绘制五角星
            sealWidth: '4.2',
            companyName: this.comName,
            sealHeight: '4.2',
          })
          await ApiCreadit.signFile({
            telephone: this.telephone || this.email,
            verifyCode: this.verifyCode,
            comId: this.tokenInfo.comId,
            legalCreditUrl: this.legalCreditUrl,
            userId: this.tokenInfo.userId,
            sealBase64,
          })
          if (!this.isZdd) {
            await this.makeOtherSeals()
          }
          this.$router.push('/signSuccess')
        } catch (error) {
          console.error(error)
        } finally {
          this.confirmLoading = false
        }
      },

      async makeOtherSeals() {
        try {
          // 合同专用章
          const seal1 = makeSeal({
            radioFlag: 1, // 是否绘制五角星
            sealWidth: '4.2',
            sealHeight: '4.2',
            companyName: this.comName,
            text: '合同专用章',
          })

          // 财务专用章
          const seal2 = makeSeal({
            radioFlag: 1, // 是否绘制五角星
            sealWidth: '3.8',
            sealHeight: '3.8',
            companyName: this.comName,
            text: '财务专用章',
          })

          // 发票专用章 - 椭圆章
          const seal3 = drawEllipseSeal({
            companyName: this.comName,
            code: '',
            text: '发票专用章',
          })

          // 合同专用章 model
          const seal1Model = {
            sealType: 1,
            sealName: '合同专用章',
            diametre: 2,
            siding: '1.2mm',
            rankWord: '合同专用章',
            sealSource: 0,
            sealImg: seal1,
          }

          // 财务专用章
          const seal2Model = {
            sealType: 1,
            diametre: 0,
            sealName: '财务专用章',
            rankWord: '财务专用章',
            siding: '1.2mm',
            sealSource: 0,
            sealImg: seal2,
          }

          // 发票专用章
          const seal3Model = {
            sealType: 4,
            diametre: 0,
            sealName: '发票专用章',
            sealSource: 0,
            centerWord: '',
            siding: '1.0mm',
            rankWord: '发票专用章',
            sealImg: seal3,
          }
          const model = {
            list: [seal1Model, seal2Model, seal3Model],
            comId: this.tokenInfo.comId,
            userId: this.tokenInfo.userId,
            systemId: this.systemInfo.systemId,
          }
          await sealApi.makeBatchSealOnline(model)
        } catch (error) {
          console.error(error)
        }
      },

      getSignFile() {
        ApiCreadit.getSignFile({
          comId: this.tokenInfo.comId,
          userId: this.tokenInfo.userId,
        }).then(res => {
          this.legalCreditUrl = res.legalCreditUrl
          this.comName = res.comName
        })
      },
      sendCode() {
        if (this.telephone) {
          // 手机号
          if (!this.$checkPhone(this.telephone)) {
            Toast('请输入正确的手机号')
            return
          }
          Api.getTelCode({
            telephone: this.telephone,
            type: 2,
          }).then(res => {
            this.setCode()
          })
        } else {
          // 邮箱
          Api.getEmailCode({
            email: this.email,
            type: 5,
          }).then(res => {
            this.setCode()
          })
        }
      },
      setCode() {
        this.isSend = true
        this.timer = setInterval(() => {
          if (!this.num) {
            clearInterval(this.timer)
            this.num = 60
            this.isSend = false
            return
          }
          this.num -= 1
        }, 1000)
      },
    },
  }
</script>
<style lang="less" scoped>
  .signCreaditBook-wrap {
    .content {
      padding: 0 24px;
      .companyName {
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #3c4043;
        line-height: 24px;
        margin-bottom: 8px;
        span {
          color: #1676ff;
        }
      }
      .userName {
        height: 20px;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #6d7790;
        line-height: 20px;
        margin-bottom: 22px;
      }
      .img-wrap {
        width: 300px;
        height: 384px;
        background: #f0f0f0;
        border-radius: 1px;
        margin: 0 auto;
        padding: 12px;
        margin-bottom: 47px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .btn {
        width: 327px;
        height: 44px;
        background: #1676ff;
        border-radius: 4px;
        box-shadow: 0px 6px 20px 0px rgba(78, 128, 245, 0.16);
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 44px;
        text-align: center;
      }
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .code-wrap {
        width: 306px;
        // height: 267px;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 6px;
        .content {
          padding: 24px;
          padding-bottom: 0;
        }
        .title {
          height: 20px;
          font-size: 20px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #111a34;
          line-height: 20px;
          text-align: center;
          margin-bottom: 24px;
        }
        .input {
          width: 257px;
          height: 46px;
          background: #f4f5f6;
          border: 1px solid #e2e4ea;
          border-radius: 4px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #c1c4cd;
          line-height: 46px;
          padding-left: 8px;
          margin-bottom: 16px;
        }
        .code {
          margin-bottom: 41px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px 0 12px 9px;
          border: 1px solid #e2e4ea;
          border-radius: 4px;
          .label {
            width: 109px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            color: #1676ff;
            line-height: 20px;
            border-left: 1px solid #e0e0e0;
          }
        }
        .btn-wrap {
          border-top: 1px solid #e0e0e0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          overflow: hidden;
          .left {
            // height: 25px;
            font-size: 18px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            color: #111a34;
            line-height: 25px;
            text-align: center;
            flex: 1;
          }
          .right {
            // height: 25px;
            border-radius: 0;
            font-size: 18px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            color: #1676ff;
            line-height: 25px;
            text-align: center;
            flex: 1;
            border: none;
            padding: 0;
            border-left: 1px solid #e0e0e0;
          }
        }
      }
    }
  }
</style>
